<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="getCoupon">
      <el-tab-pane label="未使用" name="101"></el-tab-pane>
      <el-tab-pane label="已使用" name="102"></el-tab-pane>
      <el-tab-pane label="已失效" name="103"></el-tab-pane>
    </el-tabs>
    <el-table :data="tableData">
      <el-table-column prop="hblx" label="优惠券类型" align="center" />
      <el-table-column prop="qf_money" label="优惠券门槛金额" align="center" />
      <el-table-column prop="money" label="优惠券金额" align="center" />
      <el-table-column prop="addtime" label="发放时间" align="center" />
      <el-table-column prop="hbbz" label="优惠券备注" align="center" />
      <el-table-column prop="sysj" label="使用时间" align="center" />
      <!-- <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div class="check flex_c_c" @click="handleClick(scope.row)">查看</div>
        </template>
      </el-table-column> -->
    </el-table>
    <pagination :total="total" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" />
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue";

export default {
  components: {
    pagination,
  },
  data() {
    return {
      activeName: "101", //101未使用 102已使用 103已过期
      tableData: [],
      total: 0,
      page: 1,
      page_size: 10,
    };
  },
  created() {
    this.getCoupon();
  },
  methods: {
    getCoupon() {
      this.$api("account.getCoupon", {
        status: this.activeName,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.tableData = res.data;
        this.total = res.listTotal;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getCoupon();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getCoupon();
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>
